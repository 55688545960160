import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overwrite"
    }}>{`Overwrite`}</h1>
    <p>{`You can alter the functinality of the chat by giving it arguments before running Cobrowse.create.
This can be achieved forexample:`}</p>
    <h2 {...{
      "id": "url-hash"
    }}>{`URL Hash`}</h2>
    <p>{`Running the chat with script below you can alter any property of chat when the URL is loaded. The example below changes chat language to Finnish and team to sales.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// user URL https://smilee.io/#locale=fin&context=sales etc to override language and team of chat
var hash = window.location.hash;
var args = {};

if (hash) {
  hash = hash.substr(1).split("&");
  for (var i in hash) {
    var kv = hash[i].split("=");
    var key = kv[0];
    var val = decodeURIComponent(kv[1]);
    if (val === "true") {
      val = true;
    } else if (val === "false") {
      val = false;
    }
    args[key] = val;
  }
}

var opts = {
  serverUrl: "https://agent.smilee.fi",
  apiKey: "LQt/yocAfcWRAt...",
};

// This needs to be done after the opts is declared in order to override the properties
// but before the Cobrowse.create is called
for (var key in args) {
  opts[key] = args[key];
}

Cobrowse.create(opts);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      